export const portoInsurers = [
  { label: 'Alice', value: 'Alice' },
  { label: 'Allianz', value: 'Allianz' },
  { label: 'Amil', value: 'Amil' },
  { label: 'Amnha', value: 'Amnha' },
  { label: 'Ampla', value: 'Ampla' },
  { label: 'Ana Costa', value: 'Ana Costa' },
  { label: 'Assim', value: 'Assim' },
  { label: 'Ativa', value: 'Ativa' },
  { label: 'Blue Med', value: 'Blue Med' },
  { label: 'Bradesco Saúde', value: 'Bradesco Saúde' },
  { label: 'Caberj Integral', value: 'Caberj Integral' },
  { label: 'Care Pluss', value: 'Care Pluss' },
  { label: 'Casf', value: 'Casf' },
  { label: 'Cassi', value: 'Cassi' },
  { label: 'Cemeru', value: 'Cemeru' },
  { label: 'Demais', value: 'Demais' },
  { label: 'Geap', value: 'Geap' },
  { label: 'Golden Cross', value: 'Golden Cross' },
  { label: 'Hapvida', value: 'Hapvida' },
  { label: 'Lincx', value: 'Lincx' },
  { label: 'Medical', value: 'Medical' },
  { label: 'MedSenior', value: 'MedSenior' },
  { label: 'NotreDame Intermédica', value: 'NotreDame Intermédica' },
  { label: 'Porto', value: 'Porto' },
  { label: 'Omint', value: 'Omint' },
  { label: 'One Health', value: 'One Health' },
  { label: 'Policlin', value: 'Policlin' },
  { label: 'Prevent Senior', value: 'Prevent Senior' },
  { label: 'Sami', value: 'Sami' },
  { label: 'Santa Casa SJC', value: 'Santa Casa SJC' },
  { label: 'Santa Saúde', value: 'Santa Saúde' },
  { label: 'Santa Tereza', value: 'Santa Tereza' },
  { label: 'São Cristóvão', value: 'São Cristóvão' },
  { label: 'São Miguel', value: 'São Miguel' },
  { label: 'Saúde Beneficência', value: 'Saúde Beneficência' },
  { label: 'Seguros Unimed', value: 'Seguros Unimed' },
  { label: 'Sobam', value: 'Sobam' },
  { label: 'Sompo', value: 'Sompo' },
  { label: 'Sulamérica', value: 'Sulamérica' },
  { label: 'Trasmontano', value: 'Trasmontano' },
  { label: 'Unimed(todas)', value: 'Unimed(todas)' },
]
